.login-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #EEE4CE;
}

.login-container .button {
  margin-top: 146px;
  padding: 16px 125px;
  border-radius: 48px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  letter-spacing: 0.01em;
  background-color: #152724;
  color: #D5E67E;
  cursor: pointer;
}
