.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0, 0.4);
}

.modal-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #152724;
  border-radius: 10px;
  background-color: #EEE4CE;
}

.modal-container .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: 'Open Sans', serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -1.24px;
  color: #152724;
}

.modal-container .close {
  cursor: pointer;
}
