.delete-user-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete-user-container .description {
  margin-bottom: 18px;
  font-family: 'Open Sans', serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -1.24px;
  color: #152724;
}

.delete-user-container .buttons {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.delete-user-container .buttons .button {
  padding: 8px;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  background-color: #152724;
  color: #D5E67E;
}

