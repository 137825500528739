.pin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pin .header {
  margin-bottom: 8px;
  font-family: 'Noto Serif', serif;
  font-size: 36px;
  line-height: 38px;
  letter-spacing: -2.24px;
  color: #152724;
}

.pin .description {
  margin-bottom: 86px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.5px;
  font-weight: 500;
  color: black;
}

.pin .input-container {
  border-bottom: 1px solid #152724;
  padding-bottom: 12px;
  display: flex;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 23px;
  font-weight: 500;
  color: #152724;
}

.pin input {
  border: none;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 23px;
  font-weight: 500;
  text-align: center;
  background-color: #EEE4CE;
  color: #152724;
}

.pin input:focus {
  outline: none;
}
