.users-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.users-container .nav-bar {
  width: 300px;
}

.users-container .users {
  flex: 1;
  padding: 25px;
  display: flex;
  flex-direction: column;
  background-color: #EEE4CE;
  overflow: auto;
}

.users-container .header {
  margin-bottom: 15px;
  font-family: 'Noto Serif', serif;
  font-size: 36px;
  letter-spacing: -2.24px;
  color: #152724;
}

.users-container .tool-bar {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10px;
}

.users-container .table {
  flex: 1;
  margin: 0;
  padding: 4px 0;
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-template-rows: repeat(auto-fill, 35px);
  border: 1px solid #8A9392;
  border-radius: 8px;
  background-color: #FCF0E3;
  font-family: 'Open Sans', serif;
  font-size: 16px;
  color: #152724;
  overflow-y: scroll;
}

.users-container .table .table-header {
  padding: 4px 0 4px 20px;
  border-bottom: 1px solid #8A9392;
  font-weight: 600;
}

.users-container .table .table-row {
  padding: 4px 0 4px 20px;
  border-bottom: 1px solid #8A9392;
  font-weight: 400;
}

.users-container .table .checkbox {
  margin: 0;
  cursor: pointer;
}

.tool-bar .delete-button {
  padding: 8px;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  background-color: #152724;
  color: #D5E67E;
}
